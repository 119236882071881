import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import { $api } from 'shared/api/api';
import { JsonToFormData } from 'shared/lib/JsonToFormData';
import { BlockLoader } from 'shared/ui/BlockLoader';
import StatisticTable from 'shared/ui/Statistic/Table';
import { Title } from 'shared/ui/Title';
import { Wrapper } from 'shared/ui/Wrapper';
import cls from 'shared/ui/Statistic/Statistic.module.scss';
import { Button, ButtonTheme } from 'shared/ui/Button';
import ExportB from 'shared/assets/icons/exportB.svg';
var COLUMNS = {
    pos: '№',
    name: 'Шаг',
    question: 'Вопрос',
    answer: 'Ответ',
    publish: 'Опубликован',
    score: 'Баллы',
    time: 'Время',
    finished: 'Пройден',
};
export default function LessonStepsPage() {
    var lessonId = useParams().lessonId;
    var searchParams = useSearchParams()[0];
    var email = searchParams.get('user');
    var lessonName = searchParams.get('lesson');
    var _a = useState(null), steps = _a[0], setSteps = _a[1];
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit;
    var onSubmit = function (state) {
        var _a;
        $api.post('/ext-course/frontend/manage/statistic/lesson-steps', { lessonId: lessonId, email: ((_a = state.email) === null || _a === void 0 ? void 0 : _a.length) ? state.email : email })
            .then(function (d) { return setSteps(d.data); });
    };
    var secondsToHMS = function (seconds) {
        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds % 3600) / 60);
        var remainingSeconds = seconds % 60;
        var result = "".concat((hours < 10 ? '0' : '') + hours, ":").concat(minutes < 10 ? '0' : '').concat(minutes, ":").concat(remainingSeconds < 10 ? '0' : '').concat(remainingSeconds);
        return result;
    };
    useEffect(function () {
        $api.post('/ext-course/frontend/manage/statistic/lesson-steps', { lessonId: lessonId, email: email })
            .then(function (d) { return setSteps(d.data); });
    }, []);
    return (_jsxs(Wrapper, { className: cls.wrapper, children: [_jsxs(Title, { children: ["\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B \u043F\u0440\u043E\u0445\u043E\u0436\u0434\u0435\u043D\u0438\u044F \u0443\u0440\u043E\u043A\u0430 ", _jsx("br", {}), " ", lessonName !== null ? "'".concat(lessonName, "'") : ''] }), _jsx(Button, { className: cls.button, theme: ButtonTheme.PRIMARY, onClick: function () {
                    var formData = new FormData();
                    JsonToFormData(formData, {
                        exportType: 'Xlsx',
                        exportull_w0: 1,
                        export_columns: '["0","1","2","3"]',
                        column_selector_enabled: 1,
                    });
                    $api.post('/ext-course/frontend/manage/statistic/lesson-steps?export=true', { lessonId: lessonId, email: email }).then(function (response) {
                        if (response.status === 200) {
                            var blob = new Blob(["\uFEFF".concat(response.data.export)], { type: 'text/csv;charset=utf-8' });
                            console.log(URL.createObjectURL(blob));
                            var url = window.URL.createObjectURL(blob);
                            var downloadLink = document.createElement('a');
                            downloadLink.href = url;
                            downloadLink.download = 'exposrt.csv';
                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);
                            window.URL.revokeObjectURL(url);
                            return;
                        }
                        throw new Error('Failed to fetch statistic');
                    });
                }, icon: _jsx(ExportB, {}) }), steps === null ? _jsx(BlockLoader, {})
                : (_jsx(_Fragment, { children: _jsx(StatisticTable, { columns: COLUMNS, children: steps.map(function (step, index) { return (_jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { children: index + 1 }), _jsx("td", { children: step.name }), _jsx("td", { children: step.question }), _jsx("td", { children: step.answer }), _jsx("td", { children: step.publish ? 'Да' : 'Нет' }), _jsx("td", { children: step.score }), _jsx("td", { children: secondsToHMS(step.time) }), _jsx("td", { children: step.finished ? 'Да' : 'Нет' })] }) }, index)); }) }) }))] }));
}
